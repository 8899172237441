<template>
  <div>
   <nav class="navbar fixed-top">
    <div>
      <button class="btn btn-outline-light" type="button" @click="toggleSidebar">
        <font-awesome-icon icon="bars"/>
      </button>
    </div>

    <router-link to="/dashboard">
      <img src="../assets/rosa-primarosaa.png" alt="" class="img-fluid text-left ml-2" style="max-width: 50px;">
    </router-link>

    <div class="btn-group dropleft posicion" role="group">
      <button id="btnGroupDrop1" type="button" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <!-- <img :src="imagenPerfil" alt="" class="img-fluid text-center mr-1" style="max-width: 35px; border-radius: 50%;" v-if="imagenPerfil">
          <img src="../assets/rosa-primarosaa.png" alt="" class="img-fluid text-center mr-1" style="max-width: 35px;" v-else> -->{{nombre}}
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="nav-link textColorWhite dropdown-item" @click="configuracion" v-if="idRol == 9"><font-awesome-icon icon="gear" class="textColorKey mr-0"/> Configuración</a>
          <a class="nav-link textColorWhite dropdown-item" @click="editarPerfil"><font-awesome-icon icon="user-gear" class="textColorKey mr-0"/> Editar perfil</a>
          <a class="nav-link textColorWhite dropdown-item" @click="cerrarSesion"><font-awesome-icon icon="power-off" class="textColorOff mr-1"/> Cerrar Sesión</a>
        </div>
      </div>
    </nav>
  </div>
</template>
<style>
  .navbar{
    background-color: #274054;
  }
  .btnConfig{
    font-size: 25px;
  }
  .btnConfig:hover {
    background-color: #fff; 
  }
  
  .textColorWhite{
    cursor: pointer;
    font-family: helvetica;
    font-size: 17px;
  }
  .textColorWhite:hover{
    color: #000;
  }
  .textColorKey{
    color: #274054;
  }
  .textColorOff{
    color: #DE0D0D;
  }
  .posicion{
    margin-right: 0px;
  }
</style>
<script>
	import { mapState, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState(['menu']),
      ...mapState(['autenticado']),
      ...mapState(['nombre']),
      ...mapState(['idRol']),
      ...mapState(['usuario']),
      ...mapState(['token']),
      ...mapState(['imagenPerfil']),
    },
    methods: {
      ...mapMutations(['setMenu']),
      ...mapMutations(['setAutenticado']),
      ...mapMutations(['setReiniciarValores']),
      toggleSidebar() {
        this.setMenu(!this.menu);
      },
      cerrarSesion(){
        this.$router.push("/");
        localStorage.clear();
        //this.setReiniciarValores();

        this.axios.post('cerrarLogueo', {usuario: this.usuario, token: this.token })
        .then(data=>{
          console.log(data.data.mensaje);
        });
      },
      editarPerfil(){
        this.$router.push("/editarPerfil");
      },
      configuracion(){
        this.$router.push("/usuarios");
      },
    },
  };
</script>

