<template>
  <div id="app">
    <div class="contenedor">
      <NavBar v-if="valAutenticado && token != null"/>
      <!-- <sidebar v-if="valAutenticado"/> -->
      <menus v-if="valAutenticado"/>
      <div class="top" :class="{ 'shifted-content': valMenu, 'sidebar-hidden': !valMenu  }">  
       <div v-if="isLoading" class="loading-spinner">
         <div class="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else>
        <router-view/>
      </div>
    </div>
  </div>
</div>
</template>
<style>
  .toasted-warning {
    background-color: #FF9800;
    color: white;
  }
</style>
<script>
  /*import sidebar from './components/sidebarComponent.vue';*/
  import menus from './components/componenteMenus.vue';
  import NavBar from './components/NavBar.vue';
  import { mapState, mapMutations } from 'vuex';
  export default{
    components: {
      /*sidebar,*/
      NavBar,
      menus
    },
    data:()=>({
      isLoading: false,
    }),
    methods: {
      toggleSidebar() {
        console.log(this.vista)
      },
      toggleSubmenu(index) {
        if (this.showSubmenu === index) {
          this.showSubmenu = null;
        } else {
          this.showSubmenu = index;
        }
      },
      ...mapMutations(['setAutenticado']),
      volverLogin(){
        this.$router.push("/");
        this.setAutenticado(!this.autenticado);
      },
      aceptar() {
        location.reload()
      },
    },
    computed:{
      ...mapState(['usuario']),
      ...mapState(['token']),
      ...mapState({
        autenticado: state => state.autenticado,
        valAutenticado(){
          return this.autenticado;
        }
      }),
      ...mapState({
        sidebar: state => state.menu,
        valMenu(){
          return this.sidebar;
        }
      }),
    },
    mounted(){
      /*if (localStorage.getItem('appInstanceId')) {
        if(this.autenticado){.
          this.$router.push("/");
          //this.destroyed();
        }
      } else {
        localStorage.setItem('appInstanceId', true);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
      }*/
      this.$router.beforeEach((to, from, next) => {
      this.isLoading = true; // Mostrar el indicador de carga
      next();
    });

      this.$router.afterEach(() => {
      this.isLoading = false; // Ocultar el indicador de carga
    });
    }
  };

</script>
<style>
  .top{
    margin-top: 60px;
  }
  .contenedor{
    position: relative;
  }

  .shifted-content {
    margin-left: 250px;
    transition: all 0.5s ease;
  }

  .sidebar-hidden {
    margin-left: 0;
    transition: all 0.5s ease;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }


  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }


  .loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80px; /* Aumenta el tamaño del spinner */
    height: 80px; /* Aumenta el tamaño del spinner */
    margin: -40px 0 0 -40px; /* Ajusta el margen para centrarlo */
    border: 8px solid rgba(255, 255, 255, 0.2); /* Fondo más claro */
    border-top: 8px solid #274054; /* Color del spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animación de rotación */
    z-index: 9999; /* Asegúrate de que esté sobre otros elementos */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
